import clsx from 'clsx';
import Image from 'next/image';

import { ImageFragment } from '@hultafors/solidgear/types';

import { Markdown } from '../Markdown/Markdown';
import { RouterLink } from '../RouterLink/RouterLink';

import styles from './generic-plug.module.scss';

interface GenericPlugProps {
  text: string;
  image?: ImageFragment;
  url: string;
  sizes?: string;
  center?: boolean;
  plugTitleColor?: string;
  useCtaButton?: boolean;
  bigTitleOnPlug?: boolean;
  alignPlugHeadingLeft?: boolean;
  alignPlugHeadingRight?: boolean;
  plugBigHeading?: string;
  isTwoColumns?: boolean;
  isThreeColumns?: boolean;
  isFourColumns?: boolean;
}

export const GenericPlug: React.FC<GenericPlugProps> = ({
  alignPlugHeadingLeft,
  alignPlugHeadingRight,
  center,
  text,
  url,
  image,
  sizes = '100vw',
  plugTitleColor,
  useCtaButton,
  bigTitleOnPlug,
  plugBigHeading,
  isTwoColumns,
  isThreeColumns,
  isFourColumns,
}) => {
  const stringLength = plugBigHeading?.match(/\n/g)?.length;

  const lessThanThreeLines = stringLength && stringLength < 3;

  const markdownStyles = {
    color: plugTitleColor ?? 'var(--color-white)',
    fontFamily: 'var(--font-hero)',
    maxWidth: 'unset',
  };

  return (
    <RouterLink
      href={url}
      className={clsx(
        styles['generic-plug'],
        !!image?.responsiveImage?.src &&
          styles['generic-plug__background-black'],
      )}
    >
      {image?.responsiveImage?.src && (
        <Image
          src={image.responsiveImage.src || ''}
          alt={image.alt || text}
          fill
          sizes={sizes}
          className="Image"
          placeholder="blur"
          blurDataURL={image.responsiveImage.base64 || undefined}
        />
      )}

      <div
        className={clsx(
          styles['content-wrapper'],
          !!center && styles['content-wrapper__center'],
          !!alignPlugHeadingLeft &&
            !useCtaButton &&
            styles['content-wrapper__align-left'],
          !!alignPlugHeadingRight &&
            !useCtaButton &&
            styles['content-wrapper__align-right'],
        )}
      >
        {text && useCtaButton && (
          <div className={styles['cta-box']}>
            <div className={styles['cta-text']}>{text}</div>
          </div>
        )}
        {plugBigHeading && bigTitleOnPlug && !useCtaButton && (
          <Markdown
            className={clsx(
              styles['plug-big-title'],
              !!alignPlugHeadingLeft && styles['plug-big-title__align-left'],
              !!alignPlugHeadingRight && styles['plug-big-title__align-right'],
              !!isTwoColumns && styles['plug-big-title__col-2'],
              !!isThreeColumns &&
                !lessThanThreeLines &&
                styles['plug-big-title__col-3'],
              !!isFourColumns && styles['plug-big-title__col-4'],
              lessThanThreeLines && styles['less-than-three'],
              !!isTwoColumns &&
                lessThanThreeLines &&
                styles['less-than-three__col-2'],
              !!isThreeColumns &&
                lessThanThreeLines &&
                styles['less-than-three__col-3'],
              !!isFourColumns &&
                lessThanThreeLines &&
                styles['less-than-three__col-4'],
            )}
            options={{
              overrides: {
                p: {
                  component: ({ children, ...props }) => (
                    <span {...props} style={markdownStyles}>
                      {children}
                    </span>
                  ),
                },
              },
            }}
          >
            {plugBigHeading}
          </Markdown>
        )}
      </div>
    </RouterLink>
  );
};

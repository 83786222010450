import clsx from 'clsx';
import Link from 'next/link';

import { breakpoints } from '@hultafors/shared/constants';

import { constructPlugUrl } from '@hultafors/solidgear/helpers';
import { GenericPlugBlockFragment } from '@hultafors/solidgear/types';

import { GenericPlug } from '../GenericPlug/GenericPlug';
import { Markdown } from '../Markdown/Markdown';

import styles from './generic-plug-block.module.scss';

export const GenericPlugBlock: React.FC<GenericPlugBlockFragment> = ({
  columns,
  fullwidth,
  gap,
  plugs,
  useCtaButton,
  bigTitleOnPlug,
}) => {
  const isTwoColumns = columns === 'two' || columns?.length === 0;
  const isThreeColumns = columns === 'three';
  const isFourColumns = columns === 'four';

  // TODO merge several types of plugTypes
  const plugMapper = (plug: any, index: number) => {
    const key = `Plug-${index}`;
    const link = constructPlugUrl(
      plug.__typename,
      plug?.parentSlug,
      plug?.slug,
      plug?.link,
    );
    const text = plug?.heroTitle || plug?.title || plug?.ctaText;
    if (link && text) {
      if (plug?.plugText) {
        return (
          <div key={key}>
            <GenericPlug
              text={text}
              image={plug?.image || plug.heroImage}
              url={link}
              sizes={`(min-width: ${breakpoints.tablet}) 50vw, 100vw`}
              useCtaButton={useCtaButton}
              bigTitleOnPlug={bigTitleOnPlug}
              plugTitleColor={plug?.plugTitleColor?.hex}
              plugBigHeading={plug?.plugBigHeading}
              alignPlugHeadingLeft={plug?.alignPlugHeadingLeft}
              alignPlugHeadingRight={plug?.alignPlugHeadingRight}
              isTwoColumns={isTwoColumns}
              isThreeColumns={isThreeColumns}
              isFourColumns={isFourColumns}
              center
            />
            <Link href={link} className={styles['text-content-link']}>
              <Markdown>{plug.plugText}</Markdown>
            </Link>
          </div>
        );
      }

      return (
        <GenericPlug
          text={text}
          image={plug?.image || plug.heroImage}
          url={link}
          key={key}
          sizes={`100vw, (min-width: ${breakpoints.tablet}) 50vw`}
          useCtaButton={useCtaButton}
          bigTitleOnPlug={bigTitleOnPlug}
          plugTitleColor={plug?.plugTitleColor?.hex}
          plugBigHeading={plug?.plugBigHeading}
          alignPlugHeadingLeft={plug?.alignPlugHeadingLeft}
          alignPlugHeadingRight={plug?.alignPlugHeadingRight}
          isTwoColumns={isTwoColumns}
          isThreeColumns={isThreeColumns}
          isFourColumns={isFourColumns}
          center
        />
      );
    }

    return null;
  };

  const classNames = clsx(
    styles['generic-plug-block'],
    fullwidth && styles['generic-plug-block__full-width'],
    isTwoColumns && styles['generic-plug-block__grid-two'],
    isThreeColumns && styles['generic-plug-block__grid-three'],
    isFourColumns && styles['generic-plug-block__grid-four'],
    gap && styles['generic-plug-block__gap'],
  );

  return <div className={classNames}>{plugs.map(plugMapper)}</div>;
};
